import { Action } from 'redux';
import { LoginAction, LoginActions } from './Actions';
import { LoginState } from './State';

export interface ChangePasswordAction extends Action <string> {
  type: LoginAction.ChangePassword,
  password: string,
}
export function changePasswordActionFactory(password: string) : LoginActions {
  return {
    type: LoginAction.ChangePassword,
    password: password,
  }
}

export default function handleChangePasswordAction(
  appState: LoginState,
  action: ChangePasswordAction
) : LoginState {
  return {
    ...appState,
    password: action.password,
  };
}
