import { Action } from 'redux';
import { AccountAction, AccountActions } from './Actions';
import { AccountState } from './State';

export interface ChangeOldPasswordAction extends Action<string> {
  type: AccountAction.ChangeOldPassword,
  oldPassword: string,
}

export function changeOldPasswordActionFactory(oldPassword: string): AccountActions {
  return {
    type: AccountAction.ChangeOldPassword,
    oldPassword,
  }
}

export default function handleChangeOldPassword(
  appState: AccountState,
  action: ChangeOldPasswordAction
) : AccountState {
  const { oldPassword } = action;

  return {
    ...appState,
    oldPassword
  };
}
