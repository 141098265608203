import { Dispatch } from 'redux';
import { initialConfigurationState, ConfigurationState } from './State';
import { ConfigurationActions, ConfigurationAction } from './Actions';
import handleGetDataAction, { getDataActionFactory } from './GetDataAction';
import handleSuccessAction, { successActionFactory } from './SuccessAction';
import handleErrorsAction, { errorsActionFactory } from './ErrorsAction';
import { AppState } from '../AppState';
import { flynnGalleryApiImplementation } from '../../api/FlynnGalleryApiClient';
import { restoreUserFromToken } from '../Login/Reducer';

export function configurationReducer(appState = initialConfigurationState, action: ConfigurationActions): ConfigurationState {
  switch (action.type) {
    case ConfigurationAction.GetData:
      return handleGetDataAction(appState, action);
    case ConfigurationAction.Succes:
      return handleSuccessAction(appState, action);
    case ConfigurationAction.Errors:
      return handleErrorsAction(appState, action);
    default:
      return appState;
  }
}

export function getConfiguration(isInitial = false) {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const { isLoading } = getState().configuration;

    if(isLoading) {
      console.log('already loading config');
      return;
    }

    dispatch(getDataActionFactory());

    try {
      const response = await flynnGalleryApiImplementation.configurationApi.getConfiguration();

      switch (response.status) {
        case 'success':
          const { recaptchaSiteKey } = response;

          dispatch(successActionFactory(recaptchaSiteKey));

          if(isInitial) {
            dispatch(restoreUserFromToken());
          }

          break;
        case 'error':
          const { errors } = response;

          dispatch(errorsActionFactory(errors));
          break;
      }
    } catch (e) {
      console.error(e);
      dispatch(errorsActionFactory(['Es ist ein Serverfehler aufgetreten']));
    }
  }
}
