import { Action } from 'redux';
import { AdminAction, AdminActions } from './Actions';
import { AdminState } from './State';

export interface SetErrorMessageAction extends Action<string> {
  type: AdminAction.SetErrorMessage,
  message: string | null,
}

export function setErrorMessageActionFactory(
  message: string | null,
) : AdminActions {
  return {
    type: AdminAction.SetErrorMessage,
    message: message,
  };
}

export default function handleSetErrorMessageAction(
  state: AdminState,
  action: SetErrorMessageAction,
) : AdminState {
  const { message } = action;
  
  return {
    ...state,
    errorMessage: message,
  };
}
