import { ClearDataAction } from './ClearDataAction';
import { ShowIsLoadingAction } from './ShowIsLoadingAction';
import { ChangeUsernameAction } from './ChangeUsernameAction';
import { ChangeOldPasswordAction } from './ChangeOldPasswordAction';
import { ChangePasswordAction } from './ChangePasswordAction';
import { ChangePasswordConfirmationAction } from './ChangePasswordConfirmationAction';
import { SetSuccessAction } from './SetSuccessAction';
import { ShowErrorsAction } from './ShowErrorsAction';
import { GetDataSuccessAction } from './GetDataSuccessAction';
import { SetGravatarAction } from './SetGravatar';

export enum AccountAction {
  ClearData = 'ACCOUNT/CLEAR_DATA',
  ShowIsLoading = 'ACCOUNT/SHOW_IS_LOADING',
  GetDataSuccess = 'ACCOUNT/GET_DATA_SUCCESS',
  ChangeUsername = 'ACCOUNT/CHANGE_USERNAME',
  ChangeOldPassword = 'ACCOUNT/CHANGE_OLD_PASSWORD',
  ChangePassword = 'ACCOUNT/CHANGE_PASSWORD',
  ChangePasswordConfirmation = 'ACCOUNT/CHANGE_PASSWORD_CONFIRMATION',
  SetSuccess = 'ACCOUNT/SET_SUCCESS',
  ShowErrors = 'ACCOUNT/SHOW_ERRORS',
  SetGravatar = 'ACCOUNT/SET_GRAVATAR',
}

export type AccountActions =
  | ClearDataAction
  | ShowIsLoadingAction
  | GetDataSuccessAction
  | ChangeUsernameAction
  | ChangeOldPasswordAction
  | ChangePasswordAction
  | ChangePasswordConfirmationAction
  | SetSuccessAction
  | ShowErrorsAction
  | SetGravatarAction;
