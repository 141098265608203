import React, { Component, Dispatch } from 'react';
import { Button, Form, Grid, Header, Input, Label, Message, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

import { AppState } from '../store/AppState';
import {
  changeEmailActionFactory,
  changeUsernameActionFactory,
  changePasswordActionFactory,
  changePasswordConfirmationActionFactory,
  changeActivationCodeActionFactory,
  registerAction,
} from '../store/Register/RegisterActions';
import { ValidationErrors } from '../api/register/ValidationErrors';

interface RegisterProps {
  changeEmail: (email: string) => void,
  changeUsername: (username: string) => void,
  changePassword: (password: string) => void,
  changePasswordConfirmation: (passwordConfirmation: string) => void,
  changeActivationCode: (activationCode: string) => void,
  register: (recaptcha: string) => void,
  email: string,
  username: string,
  password: string,
  passwordConfirmation: string,
  activationCode: string,
  isLoading: boolean,
  success: boolean,
  validationErrors: ValidationErrors | null,
  generalError: boolean,
  recaptchaSiteKey: string,
}

class Register extends Component<RegisterProps> {
  private recaptchaRef = React.createRef<ReCAPTCHA>();

  handleSubmit(recaptchaValue: string | null) {
    const {isLoading, register} = this.props;

    if (isLoading || recaptchaValue === null) {
      return;
    }

    register(recaptchaValue);

    if (this.recaptchaRef.current) {
      this.recaptchaRef.current.reset();
    }
  }

  private static renderErrors(errors: string[]) {
    if (errors) {
      return (
        <Label basic color='red' pointing>
          {errors.map((error, idx) =>
            <p key={idx}>
              {error}
            </p>
          )}
        </Label>
      );
    }
  }

  private renderRecaptchaError() {
    return (
      <Message error>Leider ist ein Fehler mit dem ReCAPTCHA aufgetreten. Bitte versuche es später noch einmal</Message>
    );
  }

  private renderRegisterForm() {
    const {recaptchaSiteKey} = this.props;

    if(recaptchaSiteKey.length === 0) {
      return this.renderRecaptchaError();
    }

    const {email, username, password, passwordConfirmation, activationCode, isLoading, validationErrors, generalError} = this.props;
    const {changeEmail, changeUsername, changePassword, changePasswordConfirmation, changeActivationCode} = this.props;

    return (
      <Form
        size='large'
        loading={isLoading}
        error={generalError}
        warning={validationErrors !== null}
        onSubmit={() => {
          if (this.recaptchaRef && this.recaptchaRef.current) {
            this.recaptchaRef.current.execute();
          }
        }
        }
      >
        <Segment stacked>
          <Form.Field warning={validationErrors && validationErrors.email !== null}>
            <Input
              fluid
              icon='mail'
              iconPosition='left'
              placeholder='E-Mail'
              required
              value={email}
              onChange={(_, data) => changeEmail(data.value)}
            />
            {validationErrors && Register.renderErrors(validationErrors.email)}
          </Form.Field>
          <Form.Field warning={validationErrors && validationErrors.username !== null}>
            <Input
              fluid
              icon='user'
              iconPosition='left'
              placeholder='Benutzername'
              required
              value={username}
              onChange={(e) => changeUsername(e.target.value)}
            />
            {validationErrors && Register.renderErrors(validationErrors.username)}
          </Form.Field>
          <Form.Field warning={validationErrors && validationErrors.password !== null}>
            <Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Passwort'
              type='password'
              required
              value={password}
              onChange={(e) => changePassword(e.target.value)}
            />
            {validationErrors && Register.renderErrors(validationErrors.password)}
          </Form.Field>
          <Form.Field warning={validationErrors && validationErrors.passwordConfirmation !== null}>
            <Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Passwort wiederholen'
              type='password'
              required
              value={passwordConfirmation}
              onChange={(e) => changePasswordConfirmation(e.target.value)}
            />
            {validationErrors && Register.renderErrors(validationErrors.passwordConfirmation)}
          </Form.Field>
          <Form.Field warning={validationErrors && validationErrors.activationCode !== null}>
            <Input
              fluid
              icon='key'
              iconPosition='left'
              placeholder='Freischaltcode'
              value={activationCode}
              onChange={(e) => changeActivationCode(e.target.value)}
            />
            {validationErrors && Register.renderErrors(validationErrors.activationCode)}
          </Form.Field>
          <Form.Field className='recaptcha' warning={validationErrors && validationErrors.recaptcha !== null}>
            <ReCAPTCHA
              sitekey={recaptchaSiteKey}
              ref={this.recaptchaRef}
              badge='inline'
              size='invisible'
              onChange={(value) => this.handleSubmit(value)}
            />
            {validationErrors && Register.renderErrors(validationErrors.recaptcha)}
          </Form.Field>
          <Button
            color='teal'
            fluid
            size='large'
            type='submit'
          >
            Registrieren
          </Button>
        </Segment>
        <Message error>
          Leider ist ein Fehler aufgetreten, bitte versuche es später noch einmal.
        </Message>
      </Form>
    );
  }

  render() {
    const {email, username, password, passwordConfirmation, activationCode, isLoading, success, validationErrors, generalError, recaptchaSiteKey} = this.props;
    const {changeEmail, changeUsername, changePassword, changePasswordConfirmation, changeActivationCode} = this.props;
    return (
      <Grid textAlign='center' style={{height: '90vh'}} verticalAlign='middle'>
        <Grid.Column style={{maxWidth: 450}}>
          <Header as='h2' color='teal' textAlign='center'>
            Registrieren
          </Header>
          {this.renderRegisterForm()}
          {success &&
          <Message success>
              Registierung erfolgreich.<br/>
              Bitte warte auf deine Freischaltung.<br/>
              Du kannst dich jetzt <Link to='/login'>einloggen</Link>.
          </Message>
          }
        </Grid.Column>
      </Grid>
    );
  }
}

function mapStateToProps(state: AppState) {
  const {email, username, password, passwordConfirmation, activationCode, isLoading, success, generalError, validationErrors} = state.register;
  const {recaptchaSiteKey} = state.configuration;

  return {
    email,
    username,
    password,
    passwordConfirmation,
    activationCode,
    isLoading,
    success,
    validationErrors,
    generalError,
    recaptchaSiteKey,
  }
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    changeEmail: (email: string) => dispatch(changeEmailActionFactory(email)),
    changeUsername: (username: string) => dispatch(changeUsernameActionFactory(username)),
    changePassword: (password: string) => dispatch(changePasswordActionFactory(password)),
    changePasswordConfirmation: (passwordConfirmation: string) => dispatch(changePasswordConfirmationActionFactory(passwordConfirmation)),
    changeActivationCode: (activationCode: string) => dispatch(changeActivationCodeActionFactory(activationCode)),
    register: (recaptcha: string) => dispatch(registerAction(recaptcha)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
