import { ChangeUsernameAction } from './ChangeUsernameAction';
import { ChangePasswordAction } from './ChangePasswordAction';
import { AuthenticateAction } from './AuthenticateAction';
import { SuccessAction } from './SuccessAction';
import { ShowLoginErrorsAction } from './ShowLoginErrorsAction';
import { LogoutAction } from './LogoutAction';

export enum LoginAction {
  ChangeUsername = 'LOGIN/CHANGE_USERNAME',
  ChangePassword = 'LOGIN/CHANGE_PASSWORD',
  Authenticate = 'LOGIN/AUTHENTICATE',
  HandleSuccess = 'LOGIN/HANDLE_SUCCESS',
  ShowLoginErrors = 'LOGIN/SHOW_LOGIN_ERRORS',
  Logout = 'LOGIN/LOGOUT',
}

export type LoginActions =
  | ChangeUsernameAction
  | ChangePasswordAction
  | AuthenticateAction
  | SuccessAction
  | ShowLoginErrorsAction
  | LogoutAction;

