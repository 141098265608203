import { Action } from 'redux';
import { AdminAction, AdminActions } from './Actions';
import { AdminState } from './State';

export interface StopUserIsLoadingAction extends Action<string> {
  type: AdminAction.StopUserIsLoading,
  userId: string,
}

export function stopUserIsLoadingActionFactory(
  userId: string,
) : AdminActions {
  return {
    type: AdminAction.StopUserIsLoading,
    userId: userId,
  };
}

export default function handleStopUserIsLoadingAction(
  state: AdminState,
  action: StopUserIsLoadingAction,
) : AdminState {
  const { userId } = action;
  const { users } = state;

  const updatedUsers = users.map(user => {
    if (user.id === userId) {
      user.isLoading = false;
    }
    return user;
  });

  return {
    ...state,
    users: updatedUsers,
  };
}
