import { Action } from 'redux';
import { ConfigurationAction, ConfigurationActions } from './Actions';
import { ConfigurationState } from './State';

export interface GetDataAction extends Action<string> {
  type: ConfigurationAction.GetData,
}

export function getDataActionFactory(): ConfigurationActions {
  return {
    type: ConfigurationAction.GetData,
  }
}

export default function handleGetDataAction(
  appState: ConfigurationState,
  action: GetDataAction,
): ConfigurationState {
  return {
    ...appState,
    isLoading: true,
    recaptchaSiteKey: '',
  };
}
