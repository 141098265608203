import { Action } from 'redux';
import { UserInfo } from './UserInfo';
import { Dispatch } from 'react';
import { AppState } from '../AppState';
import { flynnGalleryApiImplementation } from '../../api/FlynnGalleryApiClient';

export enum UserAction {
  GetData = 'USER/GET_DATA',
  Success = 'USER/SUCCESS',
  Clear = 'USER/CLEAR',
}

export interface GetDataAction extends Action<string> {
  type: UserAction.GetData,
}
export function getDataActionFactory() : UserActions {
  return {
    type: UserAction.GetData,
  }
}
export function getUserAction() {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(getDataActionFactory());
    
    try {
      const response = await flynnGalleryApiImplementation.userApi.getInfo();
      
      if(response !== null) {
        const userInfo = {
          id: response.id,
          username: response.username,
          roles: response.roles,
          needPasswordChange: response.needPasswordChange,
        } as UserInfo;
        dispatch(successActionFactory(userInfo));
      }
    } catch (e) {
      dispatch(clearActionFactory());
    }
  }
}

export interface SuccessAction extends Action<string> {
  type: UserAction.Success,
  userInfo : UserInfo,
}
export function successActionFactory(userInfo: UserInfo) : UserActions {
  return {
    type: UserAction.Success,
    userInfo,
  }
}

export interface ClearAction extends Action<string> {
  type: UserAction.Clear,
}
export function clearActionFactory() : UserActions {
  return {
    type: UserAction.Clear,
  }
}

export type UserActions = GetDataAction | SuccessAction | ClearAction;
