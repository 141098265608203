import { AccountState, initialAccountState } from './State';
import { AccountAction, AccountActions } from './Actions';
import handleClearData, { clearDataActionFactory } from './ClearDataAction';
import handleShowIsLoading, { showIsLoadingActionFactory } from './ShowIsLoadingAction';
import handleChangeUsername from './ChangeUsernameAction';
import handleChangeOldPassword from './ChangeOldPasswordAction';
import handleChangePassword from './ChangePasswordAction';
import handleChangePasswordConfirmation from './ChangePasswordConfirmationAction';
import handleSetSuccess, { setSuccessActionFactory } from './SetSuccessAction';
import handleShowErrors, { showErrorActionFactory } from './ShowErrorsAction';
import { Dispatch } from 'redux';
import { AppState } from '../AppState';
import handleGetDataSuccess, { getDataSuccessActionFactory } from './GetDataSuccessAction';
import { flynnGalleryApiImplementation } from '../../api/FlynnGalleryApiClient';
import { getUserAction } from '../User/UserActions';
import handleSetGravatar from './SetGravatar';

export function accountReducer(state = initialAccountState, action: AccountActions): AccountState {
  switch (action.type) {
    case AccountAction.ClearData:
      return handleClearData(state, action);
    case AccountAction.ShowIsLoading:
      return handleShowIsLoading(state, action);
    case AccountAction.GetDataSuccess:
      return handleGetDataSuccess(state, action);
    case AccountAction.ChangeUsername:
      return handleChangeUsername(state, action);
    case AccountAction.ChangeOldPassword:
      return handleChangeOldPassword(state, action);
    case AccountAction.ChangePassword:
      return handleChangePassword(state, action);
    case AccountAction.ChangePasswordConfirmation:
      return handleChangePasswordConfirmation(state, action);
    case AccountAction.SetGravatar:
      return handleSetGravatar(state, action);
    case AccountAction.SetSuccess:
      return handleSetSuccess(state, action);
    case AccountAction.ShowErrors:
      return handleShowErrors(state, action);
    default:
      return state;
  }
}

export function getData() {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const { isLoading } = getState().account;
    
    if(isLoading) {
      return;
    }
    
    dispatch(showIsLoadingActionFactory());
    dispatch(clearDataActionFactory());
    
    try {
      const response = await flynnGalleryApiImplementation.userApi.getDetails();
      
      if(response) {
        dispatch(getDataSuccessActionFactory(response.username, response.email, response.gravatarId, response.gravatarType));
      } else {
        dispatch(showErrorActionFactory(null));
      }
    } catch(e) {
      console.error(e);
      dispatch(showErrorActionFactory(null));
    }
  }
}

export function saveData() {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const { isLoading} = getState().account;
    
    if(isLoading) {
      return;
    }

    dispatch(showIsLoadingActionFactory());
    
    try {
      const { username, password, oldPassword, passwordConfirmation, gravatarType } = getState().account;
      
      const response = await flynnGalleryApiImplementation.userApi.changeDetails(username, oldPassword, password, passwordConfirmation, gravatarType);
      
      switch (response.status) {
        case 'success':
          dispatch(clearDataActionFactory());
          dispatch(setSuccessActionFactory(true));

          // Update the User details
          dispatch(getUserAction());

          // Automatically hide message after 3 seconds
          setTimeout(() => {
            dispatch(setSuccessActionFactory(false));
          }, 3000);
          break;
        case 'error':
          const { validationErrors } = response;
          dispatch(showErrorActionFactory(validationErrors));
          break;
        default:
          console.error('Unknown status: ', response.status);
          dispatch(showErrorActionFactory(null));
          break;
      }
    } catch(e) {
      console.error(e);
      dispatch(showErrorActionFactory(null));
    }
  }
}
