import { Action } from 'redux';
import { AccountAction, AccountActions } from './Actions';
import { AccountState } from './State';

export interface SetSuccessAction extends Action<string> {
  type: AccountAction.SetSuccess,
  success: boolean,
}

export function setSuccessActionFactory(show: boolean): AccountActions {
  return {
    type: AccountAction.SetSuccess,
    success: show,
  }
}

export default function handleSetSuccess(
  appState: AccountState,
  action: SetSuccessAction
) : AccountState {
  const { success } = action;
  
  return {
    ...appState,
    success,
  };
}
