import { Action, Dispatch } from 'redux';
import { AdminAction, AdminActions } from './Actions';
import { AdminState } from './State';
import { getUsers } from './Reducer';
import { AppState } from '../AppState';

export interface SetPageAction extends Action<string> {
  type: AdminAction.SetPage,
  page: number,
}

export function setPageActionFactory(
  page: number,
) : AdminActions {
  return {
    type: AdminAction.SetPage,
    page: page,
  };
}

export default function handleSetPageAction(
  state: AdminState,
  action: SetPageAction,
) : AdminState {
  const { page } = action;

  return {
    ...state,
    page: page, 
  };
}

export function setPage(page: number) {
  return async (
    dispatch: Dispatch<any>,
    getState: () => AppState
  ) => {
    dispatch(setPageActionFactory(page));
    
    dispatch(getUsers());
  };
}
