import React, { Component, Dispatch } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import Login from './components/Login';
import Logout from './components/Logout';
import ChangePassword from './components/ChangePassword';
import Home from './components/Home';
import Register from './components/Register';
import Account from './components/Account';
import Admin from './components/Admin';

import './custom.css';
import { AppState } from './store/AppState';
import { connect } from 'react-redux';
import { restoreUserFromToken } from './store/Login/Reducer';
import { getConfiguration } from './store/Configuration/Reducer';
import ResetPassword from './components/ResetPassword';

interface AppProps {
  getConfiguration: (isInitial:boolean) => void,
  userId: string | null,
  roles: string[],
  baseUrl: string,
  needPasswordChange: boolean,
}

class App extends Component<AppProps> {
  componentDidMount() {
    const { getConfiguration } = this.props;

    console.info('App mounted, restore user info');
    getConfiguration(true);
  }
  
  renderAdminRoutes() {
    const { roles } = this.props;
    
    if(!roles.includes('admin')) {
      return;
    }
    
    return (
      <>
        <Route exact path='/admin' component={Admin}/>
      </>
    );
  }

  renderUserRoutes() {
    const { userId, needPasswordChange } = this.props;

    if(userId === null) {
      // No user logged in
      return (
        <>
          <Route exact path='/' component={Home}/>
          <Route path='/register' component={Register}/>
        </>
      );
    }
    
    if(needPasswordChange) {
      return (
        <>
          <Route exact path='/logout' component={Logout}/>
          <Route path='/' component={ChangePassword}/>
        </>
      );
    }

    return (
      <>
        <Route exact path='/' component={Home} />
        <Route path='/logout' component={Logout} />
        <Route path='/account' component={Account} />
        {this.renderAdminRoutes()}
      </>
    );
  }
  
  render() {
    const { baseUrl } = this.props;
    
    return (
      <Router basename={baseUrl}>
        <Layout>
          <Route path='/login' component={Login} />
          <Route path='/reset-password' component={ResetPassword} />
          {this.renderUserRoutes()}
        </Layout>
      </Router>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    userId: state.user.userId,
    roles: state.user.roles,
    needPasswordChange: state.user.needPasswordChange,
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    getConfiguration: (isInitial: boolean) => dispatch(getConfiguration(isInitial)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
