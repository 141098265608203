import React, {Component, Dispatch} from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store/AppState';
import { Header, Label, Icon, Image, Button, Table, SemanticCOLORS, Menu, Pagination, Modal } from 'semantic-ui-react';
import { getUsers } from '../store/Admin/Reducer';
import { User } from '../api/user/GetAllUserResponse';
import { setPage } from '../store/Admin/SetPageAction';
import { OrderByValue } from '../store/Admin/State';
import { setOrderBy } from '../store/Admin/SetOrderBy';
import './Admin.scss';
import generateGravatarUrl from '../utils/gravatar';
import { activateUser } from '../store/Admin/AcitvateUserAction';
import { setErrorMessageActionFactory } from '../store/Admin/SetErrorMessageAction';

interface AdminProps {
  isLoading: boolean,
  count: number,
  page: number,
  countAll: number,
  orderBy: OrderByValue,
  descending: boolean,
  users: User[],
  errorMessage: string | null,
  getUsers: () => void,
  setPage: (page: number) => void,
  setOrderBy: (orderBy: OrderByValue, descending: boolean) => void,
  activateUser: (userId: string) => void,
  setErrorMessage: (message: string | null) => void,
}

class Admin extends Component<AdminProps> {
  componentDidMount() {
    const { getUsers } = this.props;
    getUsers();
  }

  renderLoginLabel(failedLogins : number) {
    let color : SemanticCOLORS;
    switch (failedLogins) {
      case 0:
        color='teal';
        break;
      case 1:
        color='olive';
        break;
      case 2:
        color='yellow';
        break;
      case 3:
        color='orange';
        break;
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
        color='red';
        break;
      default:
        color='black';
    }
    
    return <Label circular empty color={color} />
  }
  
  renderUserRow(userData: User) {
    const { id, username, email, registrationDate, lastLogin, failedLogins, isActivated, gravatarId, gravatarType, isLoading } = userData;
    const { activateUser } = this.props;
    return (
      <Table.Row
        key={id}
      >
        <Table.Cell>
          <Header as='h4' image>
            <Image
              src={generateGravatarUrl(gravatarId, gravatarType)}
              size='small'
            />
            <Header.Content>
              {username}
              <Header.Subheader>
              {email}
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Table.Cell>
        <Table.Cell textAlign='center'>
          <Header as='h4'>
            <Header.Content>
              {lastLogin === null ? 'Nie' : lastLogin.toLocaleString()}
              {this.renderLoginLabel(failedLogins)}
            </Header.Content>
            <Header.Subheader>
              {registrationDate.toLocaleString()}
            </Header.Subheader>
          </Header>
        </Table.Cell>
        <Table.Cell textAlign='right'>
          {!isActivated ?
            <Button
              color='teal'
              onClick={() => activateUser(id)}
              loading={isLoading}
              disabled={isLoading}
            >
              <Icon name='check' />
              Aktivieren
            </Button> : ''
          }
        </Table.Cell>
      </Table.Row>
    );
  }
  
  renderIcon(property: OrderByValue) {
    const { setOrderBy, orderBy, descending } = this.props;

    var isCurrentOrder = orderBy === property;

    var icon : 'sort' | 'sort up' | 'sort down' = isCurrentOrder
      ? descending ? 'sort down' : 'sort up'
      : 'sort';

    var desc = isCurrentOrder ? !descending : false;

    return (
      <Icon
        className='orderIcon'
        name={icon}
        onClick={() => setOrderBy(property, desc)}
      />
    );
  }
  
  closeErrorMessage() {
    const { setErrorMessage } = this.props;
    
    setErrorMessage(null);
  }

  render() {
    const { users, page, count, countAll, setPage, errorMessage } = this.props;

    return (
      <>
        <Header as='h1'>Admin</Header>
        <Header as='h2'>Benutzer</Header>
        <Table basic='very' striped columns={3}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Header as='h3'>
                  <Header.Content>
                    Benutzername
                    {this.renderIcon(OrderByValue.Username)}
                  </Header.Content>
                  <Header.Subheader>
                    E-Mail
                    {this.renderIcon(OrderByValue.Email)}
                  </Header.Subheader>
                </Header>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>
                <Header as='h3'>
                  <Header.Content>
                    Letzter Login
                    {this.renderIcon(OrderByValue.LastLoginDate)}
                  </Header.Content>
                  <Header.Subheader>
                    Registriert
                    {this.renderIcon(OrderByValue.RegistrationDate)}
                  </Header.Subheader>
                </Header>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>
                Aktionen
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {users.map(u => {
              return this.renderUserRow(u);
            })}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='3'>
                <Button basic color='teal'>
                  <Icon name='plus' />
                  Hinzufügen
                </Button>
                <Menu floated='right'>
                  <Pagination
                    totalPages={Math.ceil(countAll/count)}
                    activePage={page}
                    onPageChange={(e, d) => {
                      const page = d.activePage as number;
                      
                      if(!isNaN(page)) {
                        setPage(page);
                      }
                    }}
                  />
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Modal
          basic
          onClose={() => this.closeErrorMessage()}
          open={errorMessage !== null}
          size='small'
        >
          <Header icon>
            <Icon name='binoculars' />
            Fehler
          </Header>
          <Modal.Content>
            <p>
              {errorMessage}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='teal' inverted onClick={() => this.closeErrorMessage()}>
              <Icon name='checkmark' /> Ok
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state: AppState) {
  const {
    isLoading,
    count,
    page,
    countAll,
    users,
    errorMessage,
  } = state.admin;

  const {
    value: orderBy,
    descending,
  } = state.admin.orderBy;
  
  return {
    isLoading: isLoading,
    count: count,
    page: page,
    countAll: countAll,
    orderBy: orderBy,
    descending: descending,
    users: users,
    errorMessage: errorMessage,
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    getUsers: () => dispatch(getUsers()),
    setPage: (page: number) => dispatch(setPage(page)), 
    setOrderBy: (orderBy: OrderByValue, descending: boolean) => dispatch(setOrderBy(orderBy, descending)),
    activateUser: (userId: string) => dispatch(activateUser(userId)),
    setErrorMessage: (message: string | null) => dispatch(setErrorMessageActionFactory(message)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
