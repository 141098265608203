import { initialRegisterState, RegisterState } from './RegisterState';
import { RegisterAction, RegisterActions } from './RegisterActions';

export function registerReducer(appstate = initialRegisterState, action: RegisterActions) : RegisterState {
  switch (action.type) {
    case RegisterAction.ChangeEmail:
      const { email } = action;
      return {
        ...appstate,
        email: email,
      };
    case RegisterAction.ChangeUsername:
      const { username } = action;
      return {
        ...appstate,
        username: username,
      };
    case RegisterAction.ChangePassword:
      const { password } = action;
      return {
        ...appstate,
        password: password,
      };
    case RegisterAction.ChangePasswordConfirmation:
      const { passwordConfirmation } = action;
      return {
        ...appstate,
        passwordConfirmation: passwordConfirmation,
      };
    case RegisterAction.ChangeActivationCode:
      const { activationCode } = action;
      return {
        ...appstate,
        activationCode: activationCode,
      };
    case RegisterAction.SendData:
      return {
        ...appstate,
        isLoading: true,
        success: false,
      };
    case RegisterAction.RegisterSuccessful:
      return {
        ...initialRegisterState,
        success: true,
      };
    case RegisterAction.ShowValidationErrors:
      const { validationErrors } = action;
      return {
        ...appstate,
        isLoading: false,
        validationErrors,
      };
    case RegisterAction.ShowGeneralError:
      return {
        ...appstate,
        isLoading: false,
        validationErrors: null,
        generalError: true,
      }
    default:
      return appstate;
  }
}