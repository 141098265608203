import axios from 'axios';

import { RegisterResponse } from './RegisterResponse';
import { ValidationErrors } from './ValidationErrors';

export interface IRegisterApi {
  register(email: string, username: string, password: string, passwordConfirmation: string, activationCode: string, recaptcha: string) : Promise<RegisterResponse>,
}

class RegisterApi implements IRegisterApi {
  public async register(email: string, username: string, password: string, passwordConfirmation: string, activationCode: string, recaptcha: string) : Promise<RegisterResponse> {
    try {
      await axios.post<RegisterResponse>('api/authentication/register', {
        email: email,
        username: username,
        password: password,
        passwordConfirmation: passwordConfirmation,
        activationCode: activationCode.length > 0 ? activationCode : null,
        recaptcha: recaptcha,
      });

      return {
        success: true,
        validationErrors: null,
      };
    } catch (error) {
      let errors : ValidationErrors | null = null;
      if(error.response.status === 400 &&
        error.response.data) {
        errors = error.response.data as ValidationErrors;
        
        if(error.response.data.errors) {
          errors = error.response.data.errors as ValidationErrors;
        }
      }

      return {
        success: false,
        validationErrors: errors,
      };
    }
  }
}

export const registerApiImplementation: IRegisterApi = new RegisterApi();