import { LoginSuccessResponse } from './LoginSuccessResponse';
import axios from 'axios';
import { LoginErrorResponse, loginErrorResponseFactory } from './LoginErrorResponse';

export interface ILoginApi {
  login(username: string, password: string, recaptcha: string) : Promise<LoginSuccessResponse | LoginErrorResponse>,
}

class LoginApi implements ILoginApi {
  public async login(username: string, password: string, recaptcha: string): Promise<LoginSuccessResponse | LoginErrorResponse> {
    try {
      const { data } = await axios.post<LoginSuccessResponse>('api/authentication/login', {
        username: username,
        password: password,
        recaptcha: recaptcha,
      });
      
      data.status = 'success';
      
      return data;
    } catch (error) {
      let errorMessage = 'Es ist leider ein Fehler aufgetreten, versuche es bitte später noch einmal';
      
      if(error.response.data.errors?.Recaptcha) {
        errorMessage = error.response.data.errors.Recaptcha[0];
      }
      if(error.response.status === 404) {
        errorMessage = 'Nutzer nicht gefunden, oder Passwort nicht korrekt';
      }
      if(error.response.status === 423) {
        switch(error.response.data) {
          case 'notActivated':
            errorMessage = 'Der Nutzer wurde noch nicht aktiviert. Die Aktivierung kann ein paar Tage dauern. Sollte es dennoch länger dauern, wende dich bitte an einen Administrator';
            break;
          case 'temporarily':
            errorMessage = 'Der Nutzer wurde vorübergehend gesperrt, bitte versuche es in 5 Minuten noch einmal.';
            break;
          default:
            errorMessage = 'Der Nutzer wurde gesperrt. Bitte wende dich an einen Administrator.';
        }
      }

      return loginErrorResponseFactory([errorMessage]);
    }
  }  
}

export const loginApiImplementation: ILoginApi = new LoginApi();
