import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { Message, Header } from 'semantic-ui-react';

import { AppState } from '../store/AppState';
import { Link } from 'react-router-dom';

export interface HomeProps {
  userId: string | null,
  username: string,
}

class Home extends Component<HomeProps> {
  static displayName = Home.name;
  
  renderNotLoggedInUser() {
    return (
      <div>
        <Header as='h1'>Willkommen</Header>
        Bitte <Link to='/login'>einloggen</Link>.<br />
      </div>
    );
  }

  render() {
    const { userId, username } = this.props;
    if(userId === null) {
      return this.renderNotLoggedInUser();
    }

    return (
      <div>
        <Header as='h1'>Willkommen {username}</Header>
        <Message info>
          Willkommen auf der Webseite von Flynn. Hier entsteht eine Galerie mit den tollsten Bildern von dem kleinen Mann.
        </Message>
      </div>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    userId: state.user.userId,
    username: state.user.username,
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
