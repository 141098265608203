import { initialUserState, UserState } from './UserState';
import { UserAction, UserActions } from './UserActions';

export function userReducer(appState = initialUserState, action: UserActions): UserState {
  switch (action.type) {
    case UserAction.GetData:
      return {
        ...appState,
        loadUserInfo: true,
      }
    case UserAction.Success:
      const {id, username, roles, needPasswordChange} = action.userInfo;
      return {
        ...appState,
        loadUserInfo: false,
        userId: id,
        username: username,
        roles: roles,
        needPasswordChange: needPasswordChange,
      }
    case UserAction.Clear:
      return initialUserState;
    default:
      return appState;
  }
}
