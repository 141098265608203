import { ValidationErrors } from '../../api/register/ValidationErrors';

export interface RegisterState {
  email: string,
  username: string,
  password: string,
  passwordConfirmation: string,
  activationCode: string,
  isLoading: boolean,
  success: boolean,
  validationErrors: ValidationErrors | null,
  generalError: boolean,
}

export const initialRegisterState: RegisterState = {
  email: '',
  username: '',
  password: '',
  passwordConfirmation: '',
  activationCode: '',
  isLoading: false,
  success: false,
  validationErrors: null,
  generalError: false,
}