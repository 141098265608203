import { Action } from 'redux';
import { AdminAction, AdminActions } from './Actions';
import { User } from '../../api/user/GetAllUserResponse';
import { AdminState, OrderBy } from './State';

export interface ShowUsersAction extends Action<string> {
  type: AdminAction.ShowUsers,
  count: number,
  page: number,
  orderBy: OrderBy,
  users: User[],  
}

export function showUsersActionFactory(
  count: number,
  page: number,
  orderBy: OrderBy,
  users: User[],
) : AdminActions {
  return {
    type: AdminAction.ShowUsers,
    count: count,
    page: page,
    orderBy: orderBy,
    users: users,
  }
}

export default function handleShowUsersAction(
  state: AdminState,
  action: ShowUsersAction,
) : AdminState {
  const { count, page, orderBy, users } = action;  
  return {
    ...state,
    isLoading: false,
    countAll: count,
    page: page,
    orderBy: orderBy,
    users: users,
  }
}
