import { Action } from 'redux';
import { AccountAction, AccountActions } from './Actions';
import { AccountState, initialAccountState } from './State';

export interface ClearDataAction extends Action<string> {
  type: AccountAction.ClearData,  
}

export function clearDataActionFactory(): AccountActions {
  return {
    type: AccountAction.ClearData,
  }
}

export default function handleClearData(
  appState: AccountState,
  action: ClearDataAction
) : AccountState {
  const { username, email, gravatarId, gravatarType } = appState;
  
  return {
    ...initialAccountState,
    username,
    email,
    gravatarId,
    gravatarType,
  };
}
