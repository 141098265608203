import { GetDataAction } from './GetDataAction';
import { SuccessAction } from './SuccessAction';
import { ErrorsAction } from './ErrorsAction';

export enum ConfigurationAction {
  GetData = 'CONFIGURATION/GET_DATA',
  Succes = 'CONFIGURATION/SUCCESS',
  Errors = 'CONFIGURATION/ERRORS',
}


export type ConfigurationActions =
  | GetDataAction
  | SuccessAction
  | ErrorsAction;
