import { combineReducers } from 'redux';

import { initialLoginState, LoginState } from './Login/State';
import { loginReducer } from './Login/Reducer';
import { initialRegisterState, RegisterState } from './Register/RegisterState';
import { registerReducer } from './Register/RegisterReducer';
import { initialUserState, UserState } from './User/UserState';
import { userReducer } from './User/UserReducer';
import { initialAccountState, AccountState } from './Account/State';
import { accountReducer } from './Account/Reducer';
import { ConfigurationState, initialConfigurationState } from './Configuration/State';
import { configurationReducer } from './Configuration/Reducer';
import { AdminState, initialAdminState } from './Admin/State';
import { adminReducer } from './Admin/Reducer';

export interface AppState {
  register: RegisterState;
  login: LoginState;
  user: UserState;
  account: AccountState;
  configuration: ConfigurationState;
  admin: AdminState;
}

export const initialAppState: AppState = {
  register: initialRegisterState,
  login: initialLoginState,
  user: initialUserState,
  account: initialAccountState,
  configuration: initialConfigurationState,
  admin: initialAdminState,
};

const appReducer = combineReducers({
  register: registerReducer,
  login: loginReducer,
  user: userReducer,
  account: accountReducer,
  configuration: configurationReducer,
  admin: adminReducer,
});

export const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};
