import { Action } from 'redux';
import { AccountAction, AccountActions } from './Actions';
import { AccountState } from './State';
import { ChangeUserDetailsValidationErrors } from '../../api/user/ChangeUserDetailsValidationErrors';

export interface ShowErrorsAction extends Action<string> {
  type: AccountAction.ShowErrors,
  errors: ChangeUserDetailsValidationErrors | null,
}

export function showErrorActionFactory(errors: ChangeUserDetailsValidationErrors | null): AccountActions {
  return {
    type: AccountAction.ShowErrors,
    errors: errors,
  }
}

export default function handleShowErrors(
  appState: AccountState,
  action: ShowErrorsAction
) : AccountState {
  const { errors } = action;
  
  return {
    ...appState,
    isLoading: false,
    success: false,
    generalError: errors === null,
    validationErrors: errors,
  };
}
