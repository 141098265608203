export interface LoginState {
  username: string,
  password: string,
  isLoading: boolean,
  errors: string[],
}

export const initialLoginState: LoginState = {
  username: '',
  password: '',
  isLoading: false,
  errors: [],
}
