import axios from 'axios';
import { GetUserInfoResponse } from './GetUserInfoResponse';
import { GetUserDetailsResponse } from './GetUserDetailsResponse';
import { ChangeUserDetailsResponse } from './ChangeUserDetailsResponse';
import { ChangeUserDetailsValidationErrors } from './ChangeUserDetailsValidationErrors';
import { GetAllUserResponse } from './GetAllUserResponse';
import { OrderByValue } from '../../store/Admin/State';
import { Gravatar } from '../../store/Account/State';

export interface IUserApi {
  getInfo() : Promise<GetUserInfoResponse | null>;
  getDetails() : Promise<GetUserDetailsResponse | null>;
  changeDetails(
    username: string,
    oldPassword: string,
    password: string,
    passwordConfirmation: string,
    gravatarType: Gravatar,
  ) : Promise<ChangeUserDetailsResponse>;
  GetAllUsers(
    orderBy: OrderByValue,
    descending: boolean,
    count: number,
    page: number,
  ) : Promise<GetAllUserResponse>;
  ActivateUser(
    userId: string,
  ) : Promise<boolean>;
  SetLockStatusForUser(
    userId: string,
    lock: boolean,
  ) : Promise<boolean>;
}

class UserApi implements IUserApi {
  public async getInfo() : Promise<GetUserInfoResponse | null> {
    try {
      const {data} = await axios.get<GetUserInfoResponse>('api/user');
      return data;
    } catch (error) {
      console.log('Fehler', error);
    }
    return null;
  }
  
  public async getDetails(): Promise<GetUserDetailsResponse | null> {
    try {
      const { data } = await axios.get<GetUserDetailsResponse>('api/user/details');
      return data;
    } catch(error) {
      console.error(error);
    }
    return null;
  }
  
  public async changeDetails(
    username: string,
    oldPassword: string,
    password: string,
    passwordConfirmation:
    string,
    gravatarType: Gravatar,
  ) : Promise<ChangeUserDetailsResponse> {
    try {
      await axios.patch<ChangeUserDetailsResponse>('api/user/details', {
        username: username,
        oldPassword: oldPassword,
        password: password,
        passwordConfirmation: passwordConfirmation,
        gravtarType: gravatarType,
      });
      
      return {
        status: 'success',
        validationErrors: null,
      };
    } catch(error) {
      let errors : ChangeUserDetailsValidationErrors | null = null;
      if(error.response.status === 400 &&
        error.response.data) {
        errors = error.response.data as ChangeUserDetailsValidationErrors;
        
        if(error.response.data.errors) {
          errors = error.response.data.errors as ChangeUserDetailsValidationErrors;
        }
      }
      
      return {
        status: 'error',
        validationErrors: errors,
      };
    }
  }
  
  public async GetAllUsers(
    orderBy: OrderByValue = OrderByValue.Username,
    descending: boolean = false,
    count: number = 10,
    page: number = 1
  ) {
    try {
      let order: string;
      switch (orderBy) {
        case OrderByValue.Email:
          order = 'email';
          break;
        case OrderByValue.RegistrationDate:
          order = 'registration';
          break;
        case OrderByValue.LastLoginDate:
          order = 'lastlogin';
          break;
        default:
          order = 'username';
      }

      const url = `api/user/all`
        + `?sort=${order}`
        + (descending ? '&desc' : '')
        + `&count=${count}`
        + `&page=${page}`;
      
      const {data} = await axios.get<GetAllUserResponse>(url);
      
      return data;
    } catch(error) {
      return {
        count: 0,
        currentPage: 0,
        users: [],
        gravatarId: '',
        gravatarType: Gravatar.default,
      } as GetAllUserResponse;
    }
  }

  public async ActivateUser(
    userId: string,
  ) : Promise<boolean> {
    try {
      await axios.patch(`api/user/${userId}/activate`);
      
      return true;
    } catch(error) {
      return false;
    }
  }

  public async SetLockStatusForUser(
    userId: string,
    lock: boolean,
  ) : Promise<boolean> {
    try {
      const url = lock ? `api/user/${userId}/lock` : `api/user/${userId}/unlock`;
      await axios.patch(url);
      
      return true;
    } catch(error) {
      return false;
    }
  }
}

export const userApiImplementation: IUserApi = new UserApi();
