export interface ConfigurationState {
  recaptchaSiteKey: string,
  isLoading: boolean,
  errors: string[],
}

export const initialConfigurationState: ConfigurationState = {
  recaptchaSiteKey: '',
  isLoading: false,
  errors: [],
}
