import { Gravatar } from "../store/Account/State";

export default function generateGravatarUrl(id: string, type: Gravatar): string {
  let dParam = 'identicon';

  switch (type) {
    case Gravatar.monster:
      dParam = 'monsterid&f=y';
      break;
    case Gravatar.retro:
      dParam = 'retro&f=y';
      break;
    case Gravatar.robo:
      dParam = 'robohash&f=y';
      break;
    case Gravatar.face:
      dParam = 'wavatar&f=y';
      break;
  }

  return `https://www.gravatar.com/avatar/${id}?s=200&d=${dParam}`;
}
