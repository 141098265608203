import { LoginResponseBase } from './LoginResponseBase';

export interface LoginErrorResponse extends LoginResponseBase {
  status: 'error',
  errors: string[],
}
export function loginErrorResponseFactory(errors: string[]) : LoginErrorResponse {
  return {
    status: 'error',
    errors,
  };
}