import { ShowUsersAction } from './ShowUsersAction';
import { GetDataAction } from './GetDataAction';
import { SetPageAction } from './SetPageAction';
import { SetOrderByAction } from './SetOrderBy';
import { ActivateUserAction } from './AcitvateUserAction';
import { ActivateUserSuccessAction } from './ActivateUserSuccessAction';
import { SetErrorMessageAction } from './SetErrorMessageAction';
import { StopUserIsLoadingAction } from './StopUserIsLoadingAction';

export enum AdminAction {
  GetData = 'ADMIN/GET_DATA',
  ShowUsers = 'ADMIN/SHOW_USERS',
  SetPage = 'ADMIN/SET_PAGE',
  SetOrderBy = 'ADMIN/SET_ORDER_BY',
  ActivateUser = 'ADMIN/ACTIVATE_USER',
  ActivateUserSuccess = 'ADMIN/ACTIVATE_USER_SUCCESS',
  StopUserIsLoading = 'ADMIN/STOP_USER_IS_LOADING',
  SetUserLockStatus = 'ADMIN/SET_USER_LOCK_STATUS',
  SetErrorMessage = 'ADMIN/SET_ERROR_MESSAGE',
}

export type AdminActions =
  | ShowUsersAction
  | GetDataAction
  | SetPageAction
  | SetOrderByAction
  | ActivateUserAction
  | ActivateUserSuccessAction
  | StopUserIsLoadingAction
  | SetErrorMessageAction;
