import { Action } from 'redux';
import { Dispatch } from 'react';
import { AppState } from '../AppState';
import { flynnGalleryApiImplementation } from '../../api/FlynnGalleryApiClient';
import { ValidationErrors } from '../../api/register/ValidationErrors';

export enum RegisterAction {
  ChangeEmail = 'FLYNN_GALLERY/REGISTER/CHANGE_EMAIL',
  ChangeUsername = 'FLYNN_GALLERY/REGISTER/CHANGE_USERNAME',
  ChangePassword = 'FLYNN_GALLERY/REGISTER/CHANGE_PASSWORD',
  ChangePasswordConfirmation = 'FLYNN_GALLERY/REGISTER/CHANGE_PASSWORD_CONFIRMATION',
  ChangeActivationCode = 'FLYNN_GALLERY/REGISTER/CHANGE_ACTIVATION_CODE',
  SendData = 'FLYNN_GALLERY/REGISTER/SEND_DATA',
  RegisterSuccessful = 'FLYNN_GALLERY/REGISTER/REGISTER_SUCCESSFUL',
  ShowValidationErrors = 'FLYNN_GALLERY/REGISTER/SHOW_VALIDATION_ERRORS',
  ShowGeneralError = 'FLYNN_GALLERY/REGISTER/SHOW_GENERAL_ERROR',
}

export interface ChangeEmailAction extends Action<string> {
  type: RegisterAction.ChangeEmail,
  email: string,
}
export function changeEmailActionFactory(email: string) : RegisterActions {
  return {
    type: RegisterAction.ChangeEmail,
    email: email,
  }
}

export interface ChangeUsernameAction extends Action<string> {
  type: RegisterAction.ChangeUsername,
  username: string,
}
export function changeUsernameActionFactory(username: string) : RegisterActions {
  return {
    type: RegisterAction.ChangeUsername,
    username: username,
  }
}

export interface ChangePasswordAction extends Action<string> {
  type: RegisterAction.ChangePassword,
  password: string,
}
export function changePasswordActionFactory(password: string) : RegisterActions {
  return {
    type: RegisterAction.ChangePassword,
    password: password,
  }
}

export interface ChangePasswordConfirmationAction extends Action<string> {
  type: RegisterAction.ChangePasswordConfirmation,
  passwordConfirmation: string,
}
export function changePasswordConfirmationActionFactory(passwordConfirmation: string) : RegisterActions {
  return {
    type: RegisterAction.ChangePasswordConfirmation,
    passwordConfirmation: passwordConfirmation,
  }
}

export interface ChangeActivationCodeAction extends Action<string> {
  type: RegisterAction.ChangeActivationCode,
  activationCode: string,
}
export function changeActivationCodeActionFactory(activationCode: string) : RegisterActions {
  return {
    type: RegisterAction.ChangeActivationCode,
    activationCode: activationCode,
  }
}

export interface SendDataAction extends Action<string> {
  type: RegisterAction.SendData,
}
export function sendDataActionFactory() : RegisterActions {
  return {
    type: RegisterAction.SendData,
  }
}
export function registerAction(recaptcha: string) {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(sendDataActionFactory());

    const {email, username, password, passwordConfirmation, activationCode} = getState().register;

    try {
      const registerResponse = await flynnGalleryApiImplementation.registerApi.register(email, username, password, passwordConfirmation, activationCode, recaptcha);

      if(registerResponse.success) {
        dispatch(registerSuccessfulActionFactory());
      } else {
        if(registerResponse.validationErrors) {
          dispatch(showValidationErrorsActionFactory(registerResponse.validationErrors));
        } else {
          dispatch(showGeneralErrorActionFactory());
        }
      }
    } catch (e) {
      dispatch(showGeneralErrorActionFactory());
    }
  };
}

export interface RegisterSuccessfulAction extends Action<string> {
  type: RegisterAction.RegisterSuccessful,
}
export function registerSuccessfulActionFactory() : RegisterActions {
  return {
    type: RegisterAction.RegisterSuccessful,
  }
}

export interface ShowValidationErrorsAction extends Action<string> {
  type: RegisterAction.ShowValidationErrors,
  validationErrors: ValidationErrors,
}
export function showValidationErrorsActionFactory(errors: ValidationErrors) : RegisterActions {
  return {
    type: RegisterAction.ShowValidationErrors,
    validationErrors: errors,
  }
}

export interface ShowGeneralErrorAction extends Action<string> {
  type: RegisterAction.ShowGeneralError,
}
export function showGeneralErrorActionFactory() : RegisterActions {
  return {
    type: RegisterAction.ShowGeneralError,
  }
}

export type RegisterActions = ChangeEmailAction |
  ChangeUsernameAction |
  ChangePasswordAction |
  ChangePasswordConfirmationAction |
  ChangeActivationCodeAction |
  SendDataAction |
  RegisterSuccessfulAction |
  ShowValidationErrorsAction |
  ShowGeneralErrorAction;
