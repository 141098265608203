import { Action } from 'redux';
import { AccountAction, AccountActions } from './Actions';
import { AccountState } from './State';

export interface ChangePasswordConfirmationAction extends Action<string> {
  type: AccountAction.ChangePasswordConfirmation,
  passwordConfirmation: string,
}

export function changePasswordConfirmationActionFactory(passwordConfirmation: string): AccountActions {
  return {
    type: AccountAction.ChangePasswordConfirmation,
    passwordConfirmation,
  }
}

export default function handleChangePasswordConfirmation(
  appState: AccountState,
  action: ChangePasswordConfirmationAction
) : AccountState {
  const { passwordConfirmation } = action;

  return {
    ...appState,
    passwordConfirmation,
  };
}
