import { Action } from 'redux';
import { ConfigurationAction, ConfigurationActions } from './Actions';
import { ConfigurationState } from './State';

export interface SuccessAction extends Action<string> {
  type: ConfigurationAction.Succes,
  recaptchaSiteKey: string,
}

export function successActionFactory(recaptchaSiteKey: string): ConfigurationActions {
  return {
    type: ConfigurationAction.Succes,
    recaptchaSiteKey: recaptchaSiteKey,
  };
}

export default function handleSuccessAction(
  appState: ConfigurationState,
  action: SuccessAction,
): ConfigurationState {
  const { recaptchaSiteKey } = action;

  return {
    ...appState,
    isLoading: false,
    recaptchaSiteKey: recaptchaSiteKey,
    errors: [],
  };
}
