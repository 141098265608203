import { Action, Dispatch } from 'redux';
import { AdminAction, AdminActions } from './Actions';
import { AdminState, OrderByValue } from './State';
import { AppState } from '../AppState';
import { getUsers } from './Reducer';

export interface SetOrderByAction extends Action<string> {
  type: AdminAction.SetOrderBy,
  orderBy: OrderByValue,
  descending: boolean,
}

export function setOrderByActionFactory(
  orderBy: OrderByValue,
  descending: boolean,
) : AdminActions {
  return {
    type: AdminAction.SetOrderBy,
    orderBy: orderBy,
    descending: descending,
  };
}

export default function handleSetOrderByAction(
  state: AdminState,
  action: SetOrderByAction,
) : AdminState {
  const { orderBy, descending } = action;

  return {
    ...state,
    orderBy: {
      value: orderBy,
      descending: descending,
    },
  };
}

export function setOrderBy(orderBy: OrderByValue, descending: boolean) {
  return async (
    dispatch: Dispatch<any>,
    getState: () => AppState,
  ) => {
    dispatch(setOrderByActionFactory(orderBy, descending));

    dispatch(getUsers());
  }
}
