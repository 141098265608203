import { Action } from 'redux';
import { AdminAction, AdminActions } from './Actions';
import { AdminState } from './State';

export interface GetDataAction extends Action<string> {
  type: AdminAction.GetData,
}

export function getDataActionFactory(
) : AdminActions {
  return {
    type: AdminAction.GetData,
  };
}

export default function handleGetDataAction(
  state: AdminState,
  action: GetDataAction,
) : AdminState {
  return {
    ...state,
    isLoading: true,
    users: [],
  };
}
