import { AdminState, initialAdminState, OrderByValue } from './State';
import { AdminAction, AdminActions } from './Actions';
import handleShowUsersAction, { showUsersActionFactory } from './ShowUsersAction';
import handleGetDataAction, { getDataActionFactory } from './GetDataAction';
import { Dispatch } from 'redux';
import { AppState } from '../AppState';
import { flynnGalleryApiImplementation } from '../../api/FlynnGalleryApiClient';
import handleSetPageAction from './SetPageAction';
import handleSetOrderByAction from './SetOrderBy';
import handleActivateUserAction from './AcitvateUserAction';
import handleActivateUserSuccessAction from './ActivateUserSuccessAction';
import handleSetErrorMessageAction from './SetErrorMessageAction';
import handleStopUserIsLoadingAction from './StopUserIsLoadingAction';

export function adminReducer(
  state = initialAdminState,
  action: AdminActions,  
): AdminState {
  switch (action.type) {
    case AdminAction.SetPage:
      return handleSetPageAction(state, action);
    case AdminAction.ShowUsers:
      return handleShowUsersAction(state, action);
    case AdminAction.GetData:
      return handleGetDataAction(state, action);
    case AdminAction.SetOrderBy:
      return handleSetOrderByAction(state, action);
    case AdminAction.ActivateUser:
      return handleActivateUserAction(state, action);
    case AdminAction.ActivateUserSuccess:
      return handleActivateUserSuccessAction(state, action);
    case AdminAction.SetErrorMessage:
      return handleSetErrorMessageAction(state, action);
    case AdminAction.StopUserIsLoading:
      return handleStopUserIsLoadingAction(state, action);
    default:
      return state; 
  }
}

export function getUsers() {
  return async (
    dispatch: Dispatch<any>,
    getState: () => AppState
  ) => {
    const { isLoading } = getState().admin;
    
    if(isLoading) {
      return;
    }
    
    dispatch(getDataActionFactory());
    
    // TODO: user orderBy from state
    const { page, count, orderBy } = getState().admin;
    const { value, descending} = orderBy;
    
    try {
      const response = await flynnGalleryApiImplementation.userApi.GetAllUsers(
        orderBy.value,
        orderBy.descending,
        count,
        page,
      );
      
      dispatch(
        showUsersActionFactory(
          response.count,
          response.currentPage,
          orderBy,
          response.users,
        )
      );
    } catch (e) {
      console.error(e);
      // TODO: dispatch an error action
    }
  };
}
