import { Action, Dispatch } from 'redux';
import { LoginAction, LoginActions } from './Actions';
import { LoginState } from './State';

export interface AuthenticateAction extends Action<string> {
  type: LoginAction.Authenticate,
}
export function authenticateActionFactory() : LoginActions {
  return {
    type: LoginAction.Authenticate,
  }
}

export default function handleAuthenticateAction(
  appState: LoginState,
  action: AuthenticateAction
) {
  return {
    ...appState,
    isLoading: true,
  };
}
