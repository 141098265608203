import { Action } from 'redux';
import { LoginAction, LoginActions } from './Actions';
import { LoginState } from './State';

export interface ShowLoginErrorsAction extends Action<string> {
  type: LoginAction.ShowLoginErrors,
  errors: string[],
}
export function showLoginErrorActionFactory(errors: string[]) : LoginActions {
  return {
    type: LoginAction.ShowLoginErrors,
    errors: errors,
  }
}

export default function handleShowLoginErrorsAction(
  appState: LoginState,
  action: ShowLoginErrorsAction
) {
  return {
    ...appState,
    isLoading: false,
    errors: action.errors,
  };
}
