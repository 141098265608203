import React, { Component, Dispatch } from 'react';
import { Redirect } from 'react-router-dom';
import { AppState } from '../store/AppState';
import { connect } from 'react-redux';
import { logoutUser } from '../store/Login/Reducer';

interface LogoutProps {
  logoutUser: () => void;
}

class Logout extends Component<LogoutProps> {
  render() {
    const { logoutUser } = this.props;
    logoutUser();
    
    return (
      <Redirect to='/' />
    );
  }
}

function mapStateToProps(state: AppState) {
  return { };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    logoutUser: () => dispatch(logoutUser()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
