import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { Header, Message } from 'semantic-ui-react';

import { AppState } from '../store/AppState';

export interface ResetPasswordProps {
}

class ResetPassword extends Component<ResetPasswordProps> {
  render() {

    return (
      <div>
        <Header as='h1'>Passwort zurücksetzen</Header>
        <Message info>
          Die Funktion zum Passwort zurücksetzen kommt später, bitte wende dich an einen Administrator.
        </Message>
      </div>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {};
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
