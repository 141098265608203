import { User } from '../../api/user/GetAllUserResponse';
import { Dispatch } from 'react';
import { AppState } from '../AppState';
import { setOrderByActionFactory } from './SetOrderBy';
import { getUsers } from './Reducer';

export interface AdminState {
  isLoading: boolean,
  count: number,
  countAll: number,
  page: number,
  orderBy: OrderBy,
  users: User[],
  errorMessage: string | null,
}

export enum OrderByValue {
  Username = 0,
  Email = 1,
  RegistrationDate = 2,
  LastLoginDate = 3,
}

export interface OrderBy {
  value: OrderByValue,
  descending: boolean,
}

export const initialAdminState: AdminState = {
  isLoading: false,
  count: 10,
  countAll: 0,
  orderBy: {
    value: OrderByValue.Username,
    descending: false,
  },
  page: 1,
  users: [],
  errorMessage: null,
}
