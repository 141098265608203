import { Action } from 'redux';
import { AccountAction, AccountActions } from './Actions';
import { AccountState, Gravatar } from './State';

export interface GetDataSuccessAction extends Action<string> {
  type: AccountAction.GetDataSuccess,
  username: string,
  email: string,
  gravatarId: string,
  gravatarType: Gravatar,
}

export function getDataSuccessActionFactory(
  username: string,
  email: string,
  gravatarId: string,
  gravatarType: Gravatar,
) : AccountActions {
  return {
    type: AccountAction.GetDataSuccess,
    username: username,
    email: email,
    gravatarId: gravatarId,
    gravatarType: gravatarType,
  }
}

export default function handleGetDataSuccess(
  appState: AccountState,
  action: GetDataSuccessAction
) : AccountState {
  const { username, email, gravatarId, gravatarType } = action;

  return {
    ...appState,
    username,
    email,
    gravatarId,
    gravatarType,
    isLoading: false,
    generalError: false,
    validationErrors: null,
  };
}
