import React, { Component, Dispatch } from 'react';
import { Header } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { AppState } from '../store/AppState';

interface ChangePasswordProps {
  
}

class ChangePassword extends Component<ChangePasswordProps> {
  render() {
    return (
      <Header>Passwort ändern</Header>
    );
  }  
}

function mapStateToProps(stte: AppState) {
  return {
    
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
