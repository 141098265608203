import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';

import { AppState } from '../store/AppState';
import { Header, Segment, Form, Grid, Image, Button, Icon, Message, Label, Input, ButtonGroup, Modal } from 'semantic-ui-react';
import { changeUsernameActionFactory } from '../store/Account/ChangeUsernameAction';
import { changeOldPasswordActionFactory } from '../store/Account/ChangeOldPasswordAction';
import { changePasswordActionFactory } from '../store/Account/ChangePasswordAction';
import { changePasswordConfirmationActionFactory } from '../store/Account/ChangePasswordConfirmationAction';
import { getData, saveData } from '../store/Account/Reducer';
import { setSuccessActionFactory } from '../store/Account/SetSuccessAction';
import { ChangeUserDetailsValidationErrors } from '../api/user/ChangeUserDetailsValidationErrors';
import { Gravatar } from '../store/Account/State';
import { setGravatarActionFactory } from '../store/Account/SetGravatar';
import generateGravatarUrl from '../utils/gravatar';

interface AccountProps {
  changeUsername: (username: string) => void,
  changeOldPassword: (oldPassword: string) => void,
  changePassword: (password: string) => void,
  changePasswordConfirmation: (passwordConfirmation: string) => void,
  setGravatar: (gravatar: Gravatar) => void,
  setSuccess: (success: boolean) => void,
  getData: () => void,
  saveData: () => void,
  username: string,
  email: string,
  oldPassword: string,
  password: string,
  passwordConfirmation: string,
  gravatarId: string,
  gravatarType: Gravatar,
  isLoading: boolean,
  generalError: boolean,
  validationErrors: ChangeUserDetailsValidationErrors | null,
  success: boolean,
}

interface AccountState {
  modalGravtarIsOpen: boolean,
}

class Account extends Component<AccountProps, AccountState> {
  constructor(props: AccountProps) {
    super(props);

    this.state = {
      modalGravtarIsOpen: false,
    };
  }

  componentDidMount() {
    const { getData } = this.props;
    getData();
  }
  
  handleSubmit() {
    const { saveData } = this.props;
    saveData();
  }

  openGravatar = () => this.setState({ modalGravtarIsOpen: true});
  closeGravatar = () => this.setState({ modalGravtarIsOpen: false});

  renderSuccessMessage() {
    const { success } = this.props;

    if(!success) {
      return;
    }

    const { setSuccess } = this.props;

    return (
      <Message success onDismiss={() => setSuccess(false)}>
        Deine Änderungen wurden gespeichert
      </Message>
    );
  }

  renderGeneralErrorMessage() {
    const { generalError } = this.props;

    if(!generalError) {
      return;
    }

    return (
      <Message error>
        Es ist leider ein Fehler aufgetreten, bitte versuche es später noch einmal.
      </Message>
    );
  }

  renderInputErrors(errors: string[]) {
    if (errors) {
      return (
        <Label basic color='red' pointing>
          {errors.map((error, idx) =>
            <p key={idx}>
              {error}
            </p>
          )}
        </Label>
      );
    }
  }

  renderGravatarButton(gravatar: Gravatar) {
    const { gravatarType, setGravatar } = this.props;

    let label = 'Gravatar';

    switch (gravatar) {
      case Gravatar.monster:
        label = 'Monster';
        break;
      case Gravatar.retro:
        label = 'Retro';
        break;
      case Gravatar.robo:
        label = 'Robo';
        break;
      case Gravatar.face:
        label = 'Face';
        break;
    }

    return (
      <Button
        type='button'
        color='teal'
        active={gravatar == gravatarType}
        basic={gravatar != gravatarType}
        onClick={() => setGravatar(gravatar)}
      >
        {label}
      </Button>
    );
  }

  render() {
    const { username, email, oldPassword, password, passwordConfirmation, gravatarId, gravatarType, isLoading, success, generalError, validationErrors,
    changeUsername, changePassword, changeOldPassword, changePasswordConfirmation } = this.props;

    const { modalGravtarIsOpen } = this.state;

    return (
      <>
        <Header as='h1'>Einstellungen</Header>
        <Form
          loading={isLoading}
          success={success}
          error={generalError || validationErrors !== null}
          onSubmit={() => this.handleSubmit()}
        >
          <Segment vertical>
            <Header as='h3'>
              Profil
            </Header>
            <Grid>
              <Grid.Column width={13}>
                <Form.Field
                  warning={validationErrors && validationErrors.username !== null}
                  >
                  <label>Benutzername</label>
                  <Input
                    fluid
                    type='text'
                    placeholder='Benutzername'
                    value={username}
                    onChange={(_, data) => changeUsername(data.value)}
                  />
                  {validationErrors && this.renderInputErrors(validationErrors.username)}
                </Form.Field>
                <Form.Input
                  fluid
                  label='E-Mail'
                  type='text'
                  placeholder='E-Mail'
                  readOnly
                  value={email}
                />
                <Form.Field>
                  <label>Avatar</label>
                  <ButtonGroup>
                    {this.renderGravatarButton(Gravatar.default)}
                    {this.renderGravatarButton(Gravatar.monster)}
                    {this.renderGravatarButton(Gravatar.robo)}
                    {this.renderGravatarButton(Gravatar.retro)}
                    {this.renderGravatarButton(Gravatar.face)}
                  </ButtonGroup>
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={3}>
                <Image
                  label={{
                    as: 'a',
                    corner: 'right',
                    icon: 'star',
                    onClick: () => {
                      this.openGravatar();
                    },
                  }}
                  src={generateGravatarUrl(gravatarId, gravatarType)}
                />
              </Grid.Column>
            </Grid>
          </Segment>
          <Segment vertical>
            <Header as='h3'>
              Passwort ändern
            </Header>
            <Form.Field
              warning={validationErrors && validationErrors.oldPassword !== null}
              >
              <label>Altes Passwort</label>
              <Input
                fluid
                type='password'
                placeholder='Altes Passwort'
                value={oldPassword}
                onChange={(_, data) => changeOldPassword(data.value)}
              />
              {validationErrors && this.renderInputErrors(validationErrors.oldPassword)}
            </Form.Field>
            <Form.Group widths='equal'>
              <Form.Field
                warning={validationErrors && validationErrors.password !== null}
                >
                <label>Neues Passwort</label>
                <Input
                  fluid
                  type='password'
                  placeholder='Neues Passwort'
                  value={password}
                  onChange={(_, data) => changePassword(data.value)}
                />
                {validationErrors && this.renderInputErrors(validationErrors.password)}
              </Form.Field>
              <Form.Field
                warning={validationErrors && validationErrors.passwordConfirmation !== null}
              >
                <label>Passwort wiederholen</label>
                <Form.Input
                  fluid
                  type='password'
                  placeholder='Passwort wiederholen'
                  value={passwordConfirmation}
                  onChange={(_, data) => changePasswordConfirmation(data.value)}
                />
                {validationErrors && this.renderInputErrors(validationErrors.passwordConfirmation)}
              </Form.Field>
            </Form.Group>
          </Segment>
          <Segment vertical>
            <Header as='h3'>
              Aktionen
            </Header>
            <Button
              primary
              type='submit'
            >
              <Icon name='save' />
              Änderungen speichern
            </Button>
            {/*<Button*/}
            {/*  negative*/}
            {/*  basic*/}
            {/*  floated='right'*/}
            {/*  size='tiny'*/}
            {/*>*/}
            {/*  <Icon name='trash' />*/}
            {/*  Benutzerdaten löschen*/}
            {/*</Button>*/}
            {this.renderSuccessMessage()}
            {this.renderGeneralErrorMessage()}
          </Segment>
        </Form>
        <Modal
          open={modalGravtarIsOpen}
          onClose={this.closeGravatar}
        >
          <Modal.Header>Informationen zum Avatar</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Header>Diese Seite nutzt den Service von <a href='https://de.gravatar.com'>Gravatar</a>.</Header>
              <p>
                Gravatar ermöglicht dir, zentral ein Avatar/Profilbild zu hinterlegen, welches auf mehreren Webseite verwendet werden kann. Möchtest du den Avatar ändern, musst du dies nicht auf allen Seiten einzeln machen, sondern kannst dies zentral bei Gravatar machen.
              </p>
              <p>
                Wenn du kein Benutzerkonto bei Gravatar anlegst, wird anhand deiner E-Mail ein eindeutiger Code erzeugt. Anhand dieses Codes werden dann Avatare generiert. Diese sehen für deine E-Mail-Adresse wie folgt aus:
              </p>
              <Grid textAlign='center' columns={5} divided>
                <Grid.Row>
                <Grid.Column ce>
                    <Header as='h4'>Standard</Header>
                    <Image
                      src={`https://www.gravatar.com/avatar/${gravatarId}?s=200&d=identicon`}
                      size='small'
                      />
                  </Grid.Column>
                  <Grid.Column>
                    <Header as='h4'>Monster</Header>
                    <Image
                      src={`https://www.gravatar.com/avatar/${gravatarId}?s=200&d=monsterid&f=y`}
                      />
                  </Grid.Column>
                  <Grid.Column>
                    <Header as='h4'>Robo</Header>
                    <Image
                      src={`https://www.gravatar.com/avatar/${gravatarId}?s=200&d=robohash&f=y`}
                      />
                  </Grid.Column>
                  <Grid.Column>
                    <Header as='h4'>Retro</Header>
                    <Image
                      src={`https://www.gravatar.com/avatar/${gravatarId}?s=200&d=retro&f=y`}
                      />
                  </Grid.Column>
                  <Grid.Column>
                    <Header as='h4'>Face</Header>
                    <Image
                      src={`https://www.gravatar.com/avatar/${gravatarId}?s=200&d=wavatar&f=y`}
                      />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
              <Button
                icon='close'
                color='teal'
                content='Schließen'
                onClick={this.closeGravatar}
              />
            </Modal.Actions>
        </Modal>
      </>
    )
  };
}

function mapStateToProps(state: AppState) {
  const {
    username,
    email,
    oldPassword,
    password,
    passwordConfirmation,
    gravatarId,
    gravatarType,
    isLoading,
    success,
    generalError,
    validationErrors,
  } = state.account;
  
  return {
    username,
    email,
    oldPassword,
    password,
    passwordConfirmation,
    gravatarId,
    gravatarType,
    isLoading,
    success,
    generalError,
    validationErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    changeUsername: (username: string) => dispatch(changeUsernameActionFactory(username)),
    changeOldPassword: (oldPassword: string) => dispatch(changeOldPasswordActionFactory(oldPassword)),
    changePassword: (password: string) => dispatch(changePasswordActionFactory(password)),
    changePasswordConfirmation: (passwordConfirmation: string) => dispatch(changePasswordConfirmationActionFactory(passwordConfirmation)),
    setGravatar: (gravatar: Gravatar) => dispatch(setGravatarActionFactory(gravatar)),
    setSuccess: (success: boolean) => dispatch(setSuccessActionFactory(success)),
    getData: () => dispatch(getData()),
    saveData: () => dispatch(saveData()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
