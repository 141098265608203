import { Action } from 'redux';
import { AccountAction, AccountActions } from './Actions';
import { AccountState } from './State';

export interface ShowIsLoadingAction extends Action<string> {
  type: AccountAction.ShowIsLoading,
}

export function showIsLoadingActionFactory(): AccountActions {
  return {
    type: AccountAction.ShowIsLoading,
  }
}

export default function handleShowIsLoading(
  appState: AccountState,
  action: ShowIsLoadingAction
) : AccountState {
  return {
    ...appState,
    isLoading: true,
  };
}
