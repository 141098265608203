import { ConfigurationSuccessResponse } from './ConfigurationSuccessResponse';
import { ConfigurationErrorResponse } from './ConfigurationErrorResponse';
import axios from 'axios';

export interface IConfigurationApi {
  getConfiguration(): Promise<ConfigurationSuccessResponse | ConfigurationErrorResponse>;
}

class ConfigurationApi implements IConfigurationApi {
  public async getConfiguration(): Promise<ConfigurationSuccessResponse | ConfigurationErrorResponse> {
    try {
      const { data } = await axios.get<ConfigurationSuccessResponse>('api/ClientConfiguration');
      
      data.status = 'success';

      return data;
    } catch (error) {
      return {
        status: 'error',
        errors: ['Fehler beim Laden der Konfiguration']
      } as ConfigurationErrorResponse;
    }
  }
}

export const configurationApiImplementation: IConfigurationApi = new ConfigurationApi();
