import { Action } from 'redux';
import { LoginAction, LoginActions } from './Actions';
import { LoginState } from './State';

export interface ChangeUsernameAction extends Action<string> {
  type: LoginAction.ChangeUsername,
  username: string,
}
export function changeUsernameActionFactory(username: string) : LoginActions {
  return {
    type: LoginAction.ChangeUsername,
    username: username,
  }
}

export default function handleChangeUsername(
  appState: LoginState,
  action: ChangeUsernameAction
) : LoginState {
  return {
    ...appState,
    username: action.username,
  };
}
