export interface UserState {
  loadUserInfo: boolean,
  userId: string | null,
  username: string,
  roles: string[],
  needPasswordChange: boolean,
}

export const initialUserState: UserState = {
  loadUserInfo: false,
  userId: null,
  username: '',
  roles: [],
  needPasswordChange: false,
}
