import { Action } from 'redux';
import { AccountAction, AccountActions } from './Actions';
import { Gravatar, AccountState } from './State';

export interface SetGravatarAction extends Action<string> {
  type: AccountAction.SetGravatar,
  gravatar: Gravatar,
}

export function setGravatarActionFactory(gravatar: Gravatar): AccountActions {
  return {
    type: AccountAction.SetGravatar,
    gravatar: gravatar,
  };
}

export default function handleSetGravatar(
  appState: AccountState,
  action: SetGravatarAction,
): AccountState {
  const { gravatar } = action;

  return {
    ...appState,
    gravatarType: gravatar,
  }
}
