import { ILoginApi, loginApiImplementation } from './login/LoginApi';
import { IRegisterApi, registerApiImplementation } from './register/RegisterApi';
import { IUserApi, userApiImplementation } from './user/UserApi'
import { IConfigurationApi, configurationApiImplementation } from './configuration/ConfigurationApi';

export interface IFlynnGalleryApiClient {
  loginApi: ILoginApi,
  registerApi: IRegisterApi,
  userApi: IUserApi,
  configurationApi: IConfigurationApi,
}

class FlynnGalleryApiClient implements IFlynnGalleryApiClient {
  loginApi = loginApiImplementation;
  registerApi = registerApiImplementation;
  userApi = userApiImplementation;
  configurationApi = configurationApiImplementation;
}

export const flynnGalleryApiImplementation: IFlynnGalleryApiClient = new FlynnGalleryApiClient();
