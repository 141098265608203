import { ChangeUserDetailsValidationErrors } from '../../api/user/ChangeUserDetailsValidationErrors';

export enum Gravatar {
  default = 0,
  monster = 1,
  robo = 2,
  retro = 3,
  face = 4,
}

export interface AccountState {
  username: string,
  email: string,
  gravatarId: string,
  gravatarType: Gravatar,
  oldPassword: string,
  password: string,
  passwordConfirmation: string,
  isLoading: boolean,
  success: boolean,
  validationErrors: ChangeUserDetailsValidationErrors | null,
  generalError: boolean,
}

export const initialAccountState: AccountState = {
  username: '',
  email: '',
  gravatarId: '',
  gravatarType: Gravatar.default,
  oldPassword: '',
  password: '',
  passwordConfirmation: '',
  isLoading: false,
  success: false,
  validationErrors: null,
  generalError: false,
}
