import { Dispatch } from 'redux';
import axios from 'axios';

import { initialLoginState, LoginState } from './State';
import { LoginAction, LoginActions } from './Actions';
import handleAuthenticateAction, { authenticateActionFactory } from './AuthenticateAction';
import handleSuccessAction, { successActionFactory } from './SuccessAction';
import handleShowLoginErrorsAction, { showLoginErrorActionFactory } from './ShowLoginErrorsAction';
import { AppState } from '../AppState';
import { getUserAction, clearActionFactory } from '../User/UserActions';
import { flynnGalleryApiImplementation } from '../../api/FlynnGalleryApiClient';
import handleChangeUsernameAction from './ChangeUsernameAction';
import handleChangePasswordAction from './ChangePasswordAction';

export function loginReducer(appState = initialLoginState, action: LoginActions): LoginState {
  switch (action.type) {
    case LoginAction.ChangeUsername:
      return handleChangeUsernameAction(appState, action);
    case LoginAction.ChangePassword:
      return handleChangePasswordAction(appState, action);
    case LoginAction.Authenticate:
      return handleAuthenticateAction(appState, action);
    case LoginAction.HandleSuccess:
      return handleSuccessAction(appState, action);
    case LoginAction.ShowLoginErrors:
      return handleShowLoginErrorsAction(appState, action);
    default:
      return appState;
  }
}

export function authenticate(recaptcha: string) {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(authenticateActionFactory());
    
    try {
      const { username : usernameOrEmail, password } = getState().login;
      const response = await flynnGalleryApiImplementation.loginApi.login(usernameOrEmail, password, recaptcha);
      
      switch (response.status) {
        case 'success':
          const { token } = response;

          // TODO> move storage handling to extra class 
          localStorage.setItem('token', token);

          axios.defaults.headers.common = {
            'Authorization': `bearer ${token}`,
          };

          dispatch(successActionFactory());
          dispatch(getUserAction());
          break;
        case 'error':
          const { errors } = response;
          dispatch(showLoginErrorActionFactory(errors));
          break;
        default:
          dispatch(showLoginErrorActionFactory(['Huf']));
          break;
      }
    } catch (e) {
      console.error(e);
      dispatch(showLoginErrorActionFactory(["Bitte versuche es später noch einmal."]));
    }
  }
}

export function restoreUserFromToken() {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const token = localStorage.getItem('token');

    if(token === null) {
      return;
    }

    axios.defaults.headers.common = {
      'Authorization': `bearer ${token}`,
    };

    dispatch(getUserAction());
  }
}

export function logoutUser() {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    localStorage.removeItem('token');

    dispatch(clearActionFactory());
  }
}
