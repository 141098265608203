import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Header, Message, Button, Form, Grid, Segment } from 'semantic-ui-react';
import ReCAPTCHA from 'react-google-recaptcha';

import { AppState } from '../store/AppState';

import './Login.scss';
import { changeUsernameActionFactory } from '../store/Login/ChangeUsernameAction';
import { changePasswordActionFactory } from '../store/Login/ChangePasswordAction';
import { authenticate } from '../store/Login/Reducer';

interface LoginProps {
  changeUsername: (username: string) => void;
  changePassword: (password: string) => void;
  authenticate: (recaptcha: string) => void;
  username: string,
  password: string,
  isLoading: boolean,
  errors: string[],
  userId: string | null,
  recaptchaSiteKey: string,
}

class Login extends Component<LoginProps> {
  private recaptchaRef = React.createRef<ReCAPTCHA>();

  componentDidMount() {
    if(this.recaptchaRef.current){
      this.recaptchaRef.current.reset();
    }
  }

  handleSubmit(value: string | null) {
    const { isLoading, authenticate } = this.props;
    
    if (isLoading || value === null) {
      return;
    }

    authenticate(value);

    if(this.recaptchaRef.current)
    {
      this.recaptchaRef.current.reset();
    }
  }

  renderRecaptchaError() {
    return (
      <Message error>Leider ist ein Fehler mit dem ReCAPTCHA aufgetreten. Bitte versuche es später noch einmal</Message>
    );
  }

  renderLoginForm() {
    const {username, changeUsername, password, changePassword, isLoading, errors, recaptchaSiteKey } = this.props;

    if(recaptchaSiteKey.length === 0) {
      return this.renderRecaptchaError();
    }

    return (
      <>
        <Form
          size='large'
          loading={isLoading}
          error={errors && errors.length > 0}
          onSubmit={
            (e) => {
              if (this.recaptchaRef && this.recaptchaRef.current) {
                this.recaptchaRef.current.execute();
              }
              return false;
            }
          }
        >
          <Segment stacked>
            <Form.Input
              fluid
              icon='user'
              iconPosition='left'
              placeholder='E-Mail oder Nutzername'
              value={username}
              onChange={(e) => changeUsername(e.target.value)}
            />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Passwort'
              type='password'
              value={password}
              onChange={(e) => changePassword(e.target.value)}
            />
            <Form.Field className='recaptcha'>
              <ReCAPTCHA
                sitekey={recaptchaSiteKey}
                ref={this.recaptchaRef}
                badge='inline'
                size='invisible'
                onChange={(value) => this.handleSubmit(value)}
              />
            </Form.Field>
            <Button
              color='teal'
              fluid
              size='large'
              type='submit'
            >
              Anmelden
            </Button>
            <Message error>
              {errors && errors.map((error, idx) => <p key={idx}>{error}</p>)}
            </Message>
          </Segment>
        </Form>
        <Message>
                <Link to='/register'>Registrieren</Link> | <Link to='/reset-password'>Passwort vergessen</Link>
        </Message>
      </>
    );
  }
  
  render() {
    const {userId} = this.props;
    
    if(userId !== null) {
      return (
        <Redirect to='/' />
      );
    }
    
    return (
      <div>
        <Grid textAlign='center' style={{height: '90vh'}} verticalAlign='middle'>
          <Grid.Column style={{maxWidth: 450}}>
            <Header as='h2' color='teal' textAlign='center'>
              Anmelden
            </Header>
            {this.renderLoginForm()}
          </Grid.Column>
        </Grid>
      </div>
    );
  };
}

function mapStateToProps(state: AppState) {
  return {
    username: state.login.username,
    password: state.login.password,
    isLoading: state.login.isLoading,
    errors: state.login.errors,
    userId: state.user.userId,
    recaptchaSiteKey: state.configuration.recaptchaSiteKey,
  }
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    changeUsername: (username: string) => dispatch(changeUsernameActionFactory(username)),
    changePassword: (password: string) => dispatch(changePasswordActionFactory(password)),
    authenticate: (recaptcha: string) => dispatch(authenticate(recaptcha)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
