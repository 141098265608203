import { Action, Dispatch } from 'redux';
import { AdminAction, AdminActions } from './Actions';
import { AdminState } from './State';

export interface ActivateUserSuccessAction extends Action<string> {
  type: AdminAction.ActivateUserSuccess,
  userId: string,
}

export function activateUserSuccessActionFactory(
  userId: string,
) : AdminActions {
  return {
    type: AdminAction.ActivateUserSuccess,
    userId: userId,
  };
}

export default function handleActivateUserSuccessAction(
  state: AdminState,
  action: ActivateUserSuccessAction,
) : AdminState {
  const { userId } = action;
  const { users } = state;

  const updatedUsers = users.map(user => {
    if (user.id === userId) {
      user.isLoading = false;
      user.isActivated = true;
    }
    return user;
  });

  return {
    ...state,
    users: updatedUsers,
  };
}
