import { Action, Dispatch } from 'redux';
import { AdminAction, AdminActions } from './Actions';
import { AdminState } from './State';
import { AppState } from '../AppState';
import { flynnGalleryApiImplementation } from '../../api/FlynnGalleryApiClient';
import { activateUserSuccessActionFactory } from './ActivateUserSuccessAction';
import { setErrorMessageActionFactory } from './SetErrorMessageAction';
import { stopUserIsLoadingActionFactory } from './StopUserIsLoadingAction';

export interface ActivateUserAction extends Action<string> {
  type: AdminAction.ActivateUser,
  userId: string,
}

export function activateUserActionFactory(
  userId: string,
) : AdminActions {
  return {
    type: AdminAction.ActivateUser,
    userId: userId,
  };
}

export default function handleActivateUserAction(
  state: AdminState,
  action: ActivateUserAction,
) : AdminState {
  const { userId } = action;
  const { users } = state;

  const updatedUsers = users.map(user => {
    if (user.id === userId) {
      user.isLoading = true;
    }
    return user;
  });

  return {
    ...state,
    users: updatedUsers,
  };
}

export function activateUser(userId: string) {
  return async (
    dispatch: Dispatch<any>,
    getState: () => AppState
  ) => {
    const { users } = getState().admin;

    if (users.find(u => u.id === userId && u.isLoading)) {
      return;
    }

    dispatch(activateUserActionFactory(userId));
    
    if (await flynnGalleryApiImplementation.userApi.ActivateUser(userId)) {
      dispatch(activateUserSuccessActionFactory(userId));
    } else {
      dispatch(setErrorMessageActionFactory('Der Nutzer konnte nicht aktiviert werden'));
      dispatch(stopUserIsLoadingActionFactory(userId));
    }
  };
}
