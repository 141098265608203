import { Action } from 'redux';
import { LoginAction, LoginActions } from './Actions';
import { LoginState } from './State';

export interface SuccessAction extends Action <string> {
  type: LoginAction.HandleSuccess,
}
export function successActionFactory() : LoginActions {
  return {
    type: LoginAction.HandleSuccess,
  }
}

export default function handleSuccessAction(
  appState: LoginState,
  action: SuccessAction,
): LoginState {
  return {
    ...appState,
    isLoading: false,
    errors: [],
    username: '',
    password: '',
  };
}
