import React, { Component, Dispatch } from 'react';
import { Container, Dropdown, Menu, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './NavMenu.scss';
import { AppState } from '../store/AppState';
import { connect } from 'react-redux';

interface NavMenuProps {
  userId: string | null,
  username: string,
  roles: string[],
}

class NavMenu extends Component<NavMenuProps> {
  private renderAdminMenu() {
    const { roles } = this.props;
    
    if(roles.includes('admin')) {
      return (
        <>
          <Menu.Item name={'admin'} as={Link} to='/admin'>Admin</Menu.Item>
        </>
      );
    }

    return;
  }

  private renderMenu() {
    const { userId } = this.props;
    
    if (userId == null) {
      return;
    }
    
    return (
      <>
        {this.renderAdminMenu()}
      </>
    );
  }
  
  private renderRightMenu() {
    const {userId, username} = this.props;

    if (userId === null) {
      return (
        <Menu.Item name={'login'} as={Link} to='/login'>Login</Menu.Item>
      );
    }

    return (
      <Dropdown
        item
        text={username}
      >
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to='/account'>
            Account
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to='/logout'>Abmelden</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
  
  render() {
    return (
      <Menu>
        <Container>
          <Menu.Item as={Link} to='/' header>FlynnGallery</Menu.Item>
          {this.renderMenu()}
          <Menu.Menu position='right'>
            {this.renderRightMenu()}
          </Menu.Menu>
        </Container>
      </Menu>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    userId: state.user.userId,
    username: state.user.username,
    roles: state.user.roles,
  }
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return { };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
