import { Action } from 'redux';
import { AccountAction, AccountActions } from './Actions';
import { AccountState } from './State';

export interface ChangePasswordAction extends Action<string> {
  type: AccountAction.ChangePassword,
  password: string,
}

export function changePasswordActionFactory(password: string): AccountActions {
  return {
    type: AccountAction.ChangePassword,
    password,
  }
}

export default function handleChangePassword(
  appState: AccountState,
  action: ChangePasswordAction
) : AccountState {
  const { password } = action;

  return {
    ...appState,
    password
  };
}
