import { Action } from 'redux';
import { AccountAction, AccountActions } from './Actions';
import { AccountState } from './State';

export interface ChangeUsernameAction extends Action<string> {
  type: AccountAction.ChangeUsername,
  username: string,
}

export function changeUsernameActionFactory(username: string): AccountActions {
  return {
    type: AccountAction.ChangeUsername,
    username,
  }
}

export default function handleChangeUsername(
  appState: AccountState,
  action: ChangeUsernameAction
) : AccountState {
  const { username } = action;
  
  return {
    ...appState,
    username
  };
}
