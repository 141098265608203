import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import App from './App';
import { rootReducer } from './store/AppState';

import './index.scss';
import 'semantic-ui-css/semantic.min.css';

const rootElement = document.getElementById('root');

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

let baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
if(baseUrl === null) {
  baseUrl = '';
}

ReactDOM.render(
  <Provider store={store}>
    <App baseUrl={baseUrl}/>
  </Provider>,
  rootElement);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();

