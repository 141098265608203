import { Action } from 'redux';
import { ConfigurationAction, ConfigurationActions } from './Actions';
import { ConfigurationState } from './State';

export interface ErrorsAction extends Action<string> {
  type: ConfigurationAction.Errors,
  errors: string[],
}

export function errorsActionFactory(errors: string[]): ConfigurationActions {
  return {
    type: ConfigurationAction.Errors,
    errors: errors,
  }
}

export default function handleErrorsAction(
  appState: ConfigurationState,
  action: ErrorsAction,
): ConfigurationState {
  const { errors } = action;

  return {
    ...appState,
    isLoading: false,
    errors: errors,
  };
}

